import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@environments/environment';
import { InterpolatableTranslationObject } from '@ngx-translate/core/lib/translate.service';
import { AppDataService } from '@services/app-data.service';
import { catchError, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private readonly appDataService = inject(AppDataService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly httpClient = inject(HttpClient);

  private baseUrl = `${environment.url}/translations`;

  initialize(): Observable<void> {
    return this.fetchAdditionalTranslations()
      .pipe(
        tap(() => console.log('Initialized TranslationService')),
      );
  }

  fetchAdditionalTranslations(): Observable<void> {
    const lang = this.appDataService.appSettings()!.appLanguage;

    return this.httpClient.get<InterpolatableTranslationObject | null>(`${this.baseUrl}/${lang}`)
      .pipe(
        tap(translations => this.appDataService.addAdditionalTranslations(lang, translations ?? {})),
        map(() => void 0),
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          console.error('Failed to fetch additional translations (ignored)', error);
          return of(void 0); // this is not a breaking error, so we simply return
        }),
      );
  }
}
